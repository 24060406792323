.slide-left {
  animation: 0.5s slide-left;
}

@keyframes slide-left {
  from {
    transform: translateX(50%);
  }
  to {
    transform: translateX(0%);
  }
}

.slide-down {
  animation: 0.5s slide-down;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
